<template>
  <div class="full-card has-table">
    <div class="handle-area">
      <a-button type="primary" icon="plus" @click="openCreate('create')" v-has-permission="['addPost']">新增岗位</a-button>
      <div class="filter margin-l20" @click="filterShow = true"></div>
    </div>
    <a-card>
      <a-table
          :columns="columns"
          :data-source="list"
          :loading="tableLoading"
          :pagination="pagination"
          rowKey="id"
          bordered
          @change="pageChange"
          :scroll="{ y: 'calc(100% - 55px)'}"
      >
        <template slot="postType" slot-scope="text">
          <span v-if="text === 1">全职</span>
          <span v-if="text === 2">兼职</span>
          <span v-if="text === 3">实习</span>
        </template>
        <template slot="id" slot-scope="text, record">
          <img src="@/assets/image/common/edit.png" alt="" @click="openCreate('edit', record)" v-has-permission="['editPost']">
          <img src="@/assets/image/common/delete.png" alt="" class="margin-l20" @click="delItem(record)" v-has-permission="['deletePost']">
        </template>
      </a-table>
    </a-card>
    <a-drawer
        title="过滤器"
        placement="right"
        :visible="filterShow"
        @close="filterShow = false"
        width="400px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model labelAlign="right" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :model="filterForm" class="drawer-form">
          <a-form-model-item :wrapperCol="{ span: 24 }">
            <a-input-search placeholder="岗位名称" v-model="filterForm.postName" @search="onFilter" />
          </a-form-model-item>
          <a-row style="margin-top: 20px">
            <a-form-model-item label="岗位性质">
              <a-select v-model="filterForm.postType" placeholder="请选择岗位性质">
                <a-select-option :value="1">全职</a-select-option>
                <a-select-option :value="2">兼职</a-select-option>
                <a-select-option :value="3">实习</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="所属部门">
              <SelectDept :multiple="false" v-model="filterForm.deptId"></SelectDept>
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="search" @click="onFilter">筛选</a-button>
          <a-button style="margin-left: 10px;" icon="reload" @click="onReset">重置</a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer
        :title="`${ form.id ? '编辑' : '新增'}岗位` "
        placement="right"
        :visible="createShow"
        @close="closeCreate"
        width="500px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="rules"
            class="drawer-form"
        >
          <a-row>
            <a-form-model-item label="岗位名称" prop="postName">
              <a-input placeholder="请输入岗位名称" v-model="form.postName" />
            </a-form-model-item>
            <a-form-model-item label="岗位性质" prop="postType">
              <a-select v-model="form.postType" placeholder="请选择岗位性质">
                <a-select-option :value="1">全职</a-select-option>
                <a-select-option :value="2">兼职</a-select-option>
                <a-select-option :value="3">实习</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="所属部门" prop="deptId">
              <SelectDept :multiple="false" v-model="form.deptId"></SelectDept>
            </a-form-model-item>
            <a-form-model-item label="计划岗位数" prop="planPostNum">
              <a-input placeholder="请输入计划岗位数" v-model="form.planPostNum" />
            </a-form-model-item>
            <a-form-model-item label="岗位描述" prop="remark">
              <a-textarea placeholder="请输入岗位描述" v-model="form.remark" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="onSubmit">确定</a-button>
          <a-button icon="close" class="margin-l20" @click="closeCreate">取消</a-button>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import SelectDept from '@/components/select/selectDept'
import { getJobList, createJob, deleteJob } from '@/api/humanResources/job'


export default {
  name: 'jobInfo',
  components: {
    SelectDept
  },
  data () {
    return {
      columns: [
        {
          title: '岗位名称',
          dataIndex: 'postName'
        },
        {
          title: '岗位性质',
          dataIndex: 'postType',
          scopedSlots: { customRender: 'postType' }
        },
        {
          title: '所属部门',
          dataIndex: 'deptName'
        },
        {
          title: '岗位人数',
          dataIndex: 'planPostNum'
        },
        {
          title: '岗位描述',
          dataIndex: 'remark'
        },
        {
          title: '最近修改时间',
          dataIndex: 'updateTime'
        },
        {
          title: '修改人',
          dataIndex: 'modifier'
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        }
      ],
      list: [],
      createShow: false,
      filterShow: false,
      filterForm: {
        pageNum: 1,
        pageSize: 10
      },
      form: {},
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 10
      },
      rules: {
        postName: [
          { required: true, message: '请输入岗位名称', trigger: 'blur' }
        ],
        postType: [
          { required: true, message: '请选择岗位性质', trigger: 'change' }
        ],
        deptId: [
          { required: true, message: '请选择所属部门', trigger: 'change' }
        ]
      }
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      console.log(this.filterForm)
      return new Promise( (resolve) => {
        getJobList(this.filterForm).then(res => {
          this.list = res.data.list
          this.pagination.total = res.data.total
          this.tableLoading = false
          resolve(res.data)
        })
      })
    },
    openCreate (type, item) {
      if (item) this.form = JSON.parse(JSON.stringify(item))
      this.createShow = true
    },
    delItem (item) {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteJob({
            ids: [item.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.getList()
          })
        }
      })
    },
    onFilter () {
      this.filterForm.pageNum = 1
      this.getList().then(() => {
        this.filterShow = false
      })
    },
    onReset () {
      this.filterForm = {
        pageNum: 1,
        pageSize: 10
      }
      this.getList()
    },
    closeCreate () {
      this.form = {}
      this.createShow = false
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          createJob(this.form).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.closeCreate()
          })
        }
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    }
  }
}
</script>

<style scoped>

</style>
